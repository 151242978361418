import MobileDetect from "mobile-detect";

export type DeviceType = {
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
};

export const useDeviceType = (userAgent: string): DeviceType => {
  const md = new MobileDetect(userAgent);
  let mobile = false,
    tablet = false,
    desktop = false;
  if (md.tablet()) {
    tablet = true;
  } else if (md.mobile()) {
    mobile = true;
  } else {
    desktop = true;
  }
  return {
    mobile,
    tablet,
    desktop
  };
};

export const iOSDevice = (): boolean => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}